export const buildLocalPath = (path, filename, filetype) => {
  const pathArr = path.split(`/`).filter(slice => slice.length > 0)
  const cleanPath = pathArr.join(`/`)
  const cleanFilename = filename.replace(/\//, ``).split(`.`)[0]
  let validPath = `/${cleanPath}/${cleanFilename}`

  if (filetype) {
    const cleanFiletype = filetype.replace(/\\./, ``)
    validPath += `.${cleanFiletype}`
  }
  return validPath
}
