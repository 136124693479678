import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import { buildLocalPath } from "./utils/helpers"

type SEOMetadata = {
  description?: string
  email?: string
  facebookHandle?: string
  image?: string
  imageAlt?: string
  instagramHandle?: string
  language?: string
  logo?: string
  phone?: string
  twitterHandle?: string
}

type MetaNameTag = {
  content: string
  name: string
  property?: undefined
}

type MetaPropertyTag = {
  content: string
  name?: undefined
  property: string
}

interface FaviconProps {
  directory: string
  filetype: `png` | `ico` | `gif`
  includeSvg?: boolean
  name: string
  sizes: number[]
}

interface SEOProps {
  customTags?: [MetaNameTag | MetaPropertyTag]
  ignoreSchema?: boolean
  meta?: SEOMetadata
  overrideDefaultSchema?: boolean
  schema?: string // pass serialized json-ld
  title: string
  url: string
}

interface SiteMetadata {
  site: {
    siteMetadata: {
      favicons: FaviconProps
      meta: SEOMetadata
      schema: string
    }
  }
}

const SEO = ({
  customTags,
  ignoreSchema = false,
  meta = {},
  overrideDefaultSchema = false,
  schema = ``,
  title,
  url,
}: SEOProps): React.ReactElement => {
  const { site }: SiteMetadata = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          favicons {
            directory
            filetype
            includeSvg
            name
            sizes
          }
          meta {
            description
            email
            facebookHandle
            image
            imageAlt
            instagramHandle
            logo
            phone
            title
            twitterHandle
            url
          }
          schema
        }
      }
    }
  `)

  const metadata: SEOMetadata = Object.assign(site.siteMetadata.meta, meta)
  const { favicons } = site.siteMetadata

  // make sure meta image is prefixed with https...
  let formattedImage = metadata.image.startsWith(`//`)
    ? `https:${metadata.image}`
    : metadata.image
  // ...and contains no URL params
  formattedImage = formattedImage.includes(`?`)
    ? formattedImage.split(`?`)[0]
    : formattedImage

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: metadata.language ?? `en` }}
        title={title}
        meta={[
          {
            name: `description`,
            content: metadata.description,
          },
          {
            property: `og:locale`,
            content: `en_US`,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metadata.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: formattedImage,
          },
          {
            property: `og:image:alt`,
            content: metadata.imageAlt,
          },
          {
            property: `twitter:image`,
            content: formattedImage,
          },
          {
            property: `twitter:image:alt`,
            content: metadata.imageAlt,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metadata.description,
          },
        ]
          .concat(customTags)
          .filter(tag => tag?.content?.length > 0)}
      >
        <link rel="canonical" href={url} />
        {favicons.sizes.map((size, i) => {
          const { directory, filetype, name } = favicons
          const filename: string = `${name}-${size}x${size}`
          const path: string = buildLocalPath(directory, filename, filetype)
          return (
            <link key={i} href={path} rel="icon" type={`image/${filetype}`} />
          )
        })}
        {favicons.includeSvg ? (
          <link
            href={buildLocalPath(favicons.directory, favicons.name, `svg`)}
            rel="icon"
            type="image/svg+xml"
          />
        ) : null}
        {site.siteMetadata.schema && !ignoreSchema && !overrideDefaultSchema ? (
          <script type="application/ld+json">{site.siteMetadata.schema}</script>
        ) : null}
        {schema && !ignoreSchema ? (
          <script type="application/ld+json">{schema}</script>
        ) : null}
      </Helmet>
    </>
  )
}

export default SEO